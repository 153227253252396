<template>
  <v-row class="pt-10 manage-accounts-row">
    <v-col lg="10" md="12" offset-lg="1" offset-md="0" xl="12">
      <h3 class="text-uppercase">{{ $t("my_accounts_page.title") }}</h3>
      <v-card class="my-accounts-card">
        <v-card-title class="pa-0 ma-0">
          <h2>{{ $t("my_accounts_page.title") }}</h2>
          <v-spacer></v-spacer>
          <span class="ma-0">
            <v-btn class="bg-primary color-white border-16"> {{ $t("my_accounts_page.add_logins_button") }} </v-btn>
          </span>
        </v-card-title>
        <v-card-title class="pa-0 ma-0 mt-5">
          <p>Click account name to link it to Optmyzr</p>
          <v-spacer></v-spacer>
          <span class="ma-0">
            <img :src="imgDown" height="40" width="40" />
          </span>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="5"
          class="mt-5"
        >
          <template v-slot:body="props">
            <tbody>
              <tr
                v-for="(item, index) in props.items"
                :key="index"
                class="table-row"
              >
                <td class="py-2">
                  <h3 class="color-blue">
                    {{ item.number }}
                  </h3>
                </td>
                <td>
                  {{ item.account }}
                </td>
                <td>
                  {{ item.status }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import imgDown from "@/assets/img/searchIcon.svg";
import imgPrint from "@/assets/img/print.svg";
import imgEdit from "@/assets/img/edit.svg";

export default {
  name: "ManageAccounts",
  data() {
    return {
      imgDown,
      imgPrint,
      imgEdit,
      headers: [
        { text: "Number", value: "number" },
        { text: "Account Name", value: "account" },
        { text: "Status", value: "status" },
      ],
      items: [
        {
          number: "123",
          account: "US$10",
          status: 0,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.manage-accounts-row {
  transform: scaleX(0.9)!important;
}
.my-accounts-card {
  padding: 40px;
  box-shadow: none !important;
  border-radius: 12px;
  @media screen and (max-width: 600px) {
    padding: 20px 15px;
  }
  @media screen and (max-width: 450px) {
    padding: 20px 10px;
  }

  .down-btn {
    background-color: #f5f9fc !important;
    border-radius: 12px !important;
    box-shadow: none;
  }

  .table-row {
    height: 60px !important;

    td {
      border: none !important;
    }
  }
}
</style>
